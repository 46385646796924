<template>
	<b-card class="pb-2" no-body>
		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Search -->
				<b-col cols="12" md="2" class="mb-1 mb-md-0">
					<v-select
						class="mr-1"
						v-model="filter.status"
						:reduce="item => item.value"
						:searchable="false"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="statusOptions"
						:placeholder="$t('Please Select')"
						style="width:100%"
					>
					</v-select>
				</b-col>
				<b-col cols="8" md="2" class="mb-1 mb-md-0">
					<b-form-input
						v-model="filter.query"
						class="d-inline-block"
						:placeholder="$t('Please Enter...')"
						style="width:100%"
					/>
				</b-col>
				<b-col cols="4" md="2">
					<b-button
						variant="primary"
						@click="searchAction"
					>
						<span class="text-nowrap">{{$t('Search')}}</span>
					</b-button>
				</b-col>
				<b-col
					cols="12"
					md="6"
					class="d-flex align-items-center justify-content-start justify-content-md-end"
				>
					<b-button variant="primary" @click="newAction">
						<span class="text-nowrap">{{$t('Add Program')}}</span>
					</b-button>
				</b-col>
			</b-row>
		</div>
		<!-- data table start -->
		<b-table
			ref="dataList"
			class="position-relative"
			stacked="sm"
			hover
			show-empty
			:empty-text="$t('No Data')"
			:items="dataList"
			:fields="tableColumns"
			primary-key="uuid"
			v-loading="loading"
		>
			<!-- Column: Size -->
			<template #cell(size)="data">
				{{data.item.w}}px * {{data.item.h}}px
			</template>
			<!-- Column: status -->
			<template #cell(status)="data">
				<b-badge :variant="statusObj[data.item.status].color">{{statusObj[data.item.status].label}}</b-badge>
			</template>
			<!-- Column: updated_at -->
			<template #cell(updated_at)="data">
				{{data.item.updated_at || '/'}}
			</template>
			<!-- Column: Actions -->
			<template #cell(actions)="data">
				<!-- 详情 -->
				<feather-icon
					:id="`${data.item.uuid}-info-icon`"
					icon="InfoIcon"
					class="cursor-pointer"
					size="16"
					@click="$router.push({ name: 'programDetail', query: { uuid: data.item.uuid }})"
				/>
				<b-tooltip
					:title="$t('Details')"
					class="cursor-pointer"
					:target="`${data.item.uuid}-info-icon`"
				/>
				<!-- 编辑 -->
				<feather-icon
					:id="`${data.item.uuid}-edit-icon`"
					icon="EditIcon"
					class="cursor-pointer ml-1"
					size="16"
					@click="editAction(data.item)"
					v-if="data.item.status==0"
				/>
				<b-tooltip
					:title="$t('Edit')"
					class="cursor-pointer"
					:target="`${data.item.uuid}-edit-icon`"
				/>
				<feather-icon
					:ref="'tooltip'+data.item.uuid"
					:id="`${data.item.uuid}-preview-icon`"
					icon="CodepenIcon"
					class="cursor-pointer ml-1"
					size="16"
					@click="previewAction(data.item)"
				/>
				<b-tooltip
					:title="$t('Preview')"
					class="cursor-pointer"
					:target="`${data.item.uuid}-preview-icon`"
				/>
				<!-- 重命名 -->
				<feather-icon
					:id="`${data.item.uuid}-rename-icon`"
					icon="Edit2Icon"
					class="cursor-pointer ml-1"
					size="16"
					@click="renameAction(data.item)"
					
				/>
				<b-tooltip
					:title="$t('Rename')"
					class="cursor-pointer"
					:target="`${data.item.uuid}-rename-icon`"
				/>
				<b-dropdown
					variant="link"
					no-caret
					:right="$store.state.appConfig.isRTL"
				>

					<template #button-content>
						<feather-icon
							icon="MoreVerticalIcon"
							size="16"
							class="align-middle text-body"
						/>
					</template>
					<!-- <b-dropdown-item @click="auditAction(data.item,1)">
						<feather-icon icon="CheckSquareIcon" />
						<span class="align-middle ml-50">{{$t('Pass')}}</span>
					</b-dropdown-item>
					<b-dropdown-item @click="auditAction(data.item,0)">
						<feather-icon icon="AlertTriangleIcon" />
						<span class="align-middle ml-50">{{$t('Refuse')}}</span>
					</b-dropdown-item> -->
					<b-dropdown-item @click="completeAction(data.item)" v-if="data.item.status==0">
						<feather-icon icon="CheckSquareIcon" />
						<span class="align-middle ml-50">{{$t('Mark as completed')}}</span>
					</b-dropdown-item>
					<b-dropdown-item @click="deleteAction(data.item)">
						<feather-icon icon="Trash2Icon" />
						<span class="align-middle ml-50">{{$t('Delete')}}</span>
					</b-dropdown-item>
				</b-dropdown>
			</template>

		</b-table>
		<!-- Pagination -->
		<Pagination
			:currentPage="currentPage"
			:pageSize="pageSize"
			:count="dataList.length"
			v-if="dataList.length>0"
		></Pagination>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
		<!-- New  -->
		<New
			:is-active.sync="newSidebarVisible"
			@refetch-data="getData()"
		/>
		<!-- rename  -->
		<rename
			:is-active.sync="renameSidebarVisible"
			:activeData="activeData"
			@refetch-data="getData()"
			v-if="renameSidebarVisible"
		/>
		<!-- preview -->
		<preview :activeData="previewData" v-if="previewVisible && previewData" @close="previewVisible=false"></preview>
	</b-card>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BSidebar,VBToggle, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import New from "@/components/program/New.vue"
import Rename from "@/components/program/Rename.vue"
import Preview from "@/components/program/preview/player"
import Pagination from "@/components/public/Pagination.vue";
import { getProgram,deleteProgram, submitProgram,getProgramInfo } from "@/libs/apis/serverApi";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia, 
		BAvatar, 
		BLink,
		BBadge, 
		BDropdown, 
		BDropdownItem, 
		BPagination,
		BTooltip,
		BSidebar,
		BListGroup, BListGroupItem,
		vSelect,

		New,
		Rename,
		Preview,
		Pagination
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data () {
		return  {
			filter: {
				query: '',
				status: -1
			},
			searchQuery: '',
			statusFilter: '',
			// 0 编辑
			// 1 已完成
			statusOptions: [
				{ label: this.$t('All'), value: -1 },
				{ label: this.$t('Editing'), value: 0, color: 'primary' },
				{ label: this.$t('Completed'), value: 1, color: 'success' }
			],
			sortBy: 'id',
			isSortDirDesc: true,
			tableColumns: [
				{ key: 'uuid', sortable: false },
				{ key: 'name', label:this.$t('Name'), sortable: false },
				{ key: 'size', label: this.$t('Size'), sortable: false },
				{ key: 'status', label: this.$t('Status'), sortable: false},
				{ key: 'created_at', label: this.$t('Create Time'), sortable: false },
				{ key: 'updated_at', label: this.$t('Update Time'), sortable: false },
				{ key: 'actions', label: this.$t('Actions') },
			],
			loading: false,
			dataList: [],
			pageSize: 10,
			currentPage: 1,
			activeData: null,
			newSidebarVisible: false,
			renameSidebarVisible: false,
			previewVisible: false,
			previewData: null
		}
	},
	computed: {
		statusObj () {
			let temp = {}
			this.statusOptions.forEach(item=>{
				temp[item.value] = item
			})
			return temp
		}
	},
	created () {
		this.getData()
	},
	methods: {
		getData (page) {
			this.loading = true
			page = page || 1;
			const params = Object.assign({
				page,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}, this.filter)
			getProgram(params).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			})
			
		},
		searchAction () {
			this.getData(1)
		},
		newAction () {
			this.newSidebarVisible = true
		},
		editAction (item) {
			this.$router.push('/programEdit?uuid='+item.uuid)
		},
		renameAction(item) {
			this.activeData = item;
			this.renameSidebarVisible = true;
		},
		getDataById (uuid,cbk) {
			this.loading = true
			getProgramInfo({uuid}).then(res=>{
				this.loading = false
				let info = res.row
				this.programInfo = info
				if(!this.programInfo.content){
					this.previewData = {
						uuid: this.uuid,
						name: info.name,
						screen_w: info.w,
						screen_h: info.h,
						screen_x: 0,
						screen_y: 0,
						windows: []
					}
				}else{
					this.previewData = JSON.parse(info.content)
				}
				cbk && cbk()
			},err=>{
				this.loading = false
				this.$toast(err.message)
			})
		},
		previewAction (item){
			this.$refs['tooltip'+item.uuid].blur()
			this.getDataById(item.uuid, ()=>{
				this.previewVisible = true
			})
			
		},
		auditAction (item){
			// this.renameSidebarVisible = true
			this.$bvModal
				.msgBoxConfirm(this.$t('refuseDataTip'), {
					title: this.$t('Tips'),//'Please Confirm',
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Refuse'),
					cancelTitle: this.$t('Cancel'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(value => {
					// 
				})
		},
		getProgramDuration (info) {
			let content = info.row.content
			if(!content) return 0
			let windows = JSON.parse(content).windows
			let endTime = 0
			windows.forEach(item=>{
				let thisEndTime = item.start_time*1000 + item.window_play_times
				endTime = Math.max(endTime, thisEndTime)
			})
			return endTime
		},
		async completeAction (item) {
			let info = await getProgramInfo({uuid: item.uuid})
			let duration = this.getProgramDuration(info)
			submitProgram({
				uuid: item.uuid,
				duration: duration
			}).then((res) => {
				this.$toast(this.$t("Mark Success"));
				this.getData(this.currentPage);
			}, (err) => {
				this.$toast(err.message);
			});
		},
		deleteAction(item){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
					title: this.$t('Tips'),//'Please Confirm',
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Delete'),
					cancelTitle: this.$t('Cancel'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(isConfirm => {
					if (isConfirm) {
						deleteProgram({ uuid: item.uuid }).then((res) => {
							this.$toast(this.$t("Delete Success"));
							this.getData(this.currentPage);
						}, (err) => {
							this.$toast.error(err.message);
						});
					}
				})
		}
	}
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
